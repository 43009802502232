// gatsby-browser.js

import React from "react";
import { Layout } from "./src/layout/layout";

export const onRouteUpdate = ({ location }: any) => {
  if (process.env.NODE_ENV !== "production") {
    let colorSchema = ["default", "dark", "mono"];
    let schemaPosition = 0;
    let colorCategory = [
      "original",
      "jewel",
      "pastel",
      "earth",
      "neutral",
      "fluorescent",
      "shades",
    ];
    let categoryPosition = 0;

    document.addEventListener("keydown", (event: KeyboardEvent) => {
      if (
        event.key === "ArrowRight" &&
        schemaPosition < colorSchema.length - 1
      ) {
        schemaPosition += 1;
        console.log(colorSchema[schemaPosition]);
        document
          .querySelector("html")
          ?.setAttribute(
            "data-theme",
            `${colorSchema[schemaPosition]}-${colorCategory[categoryPosition]}`
          );
      }
      if (event.key === "ArrowLeft" && schemaPosition > 0) {
        schemaPosition -= 1;
        console.log(colorSchema[schemaPosition]);
        document
          .querySelector("html")
          ?.setAttribute(
            "data-theme",
            `${colorSchema[schemaPosition]}-${colorCategory[categoryPosition]}`
          );
      }
    });

    document.addEventListener("keydown", (event: KeyboardEvent) => {
      if (
        event.key === "ArrowUp" &&
        categoryPosition < colorCategory.length - 1
      ) {
        categoryPosition += 1;
        console.log(colorCategory[categoryPosition]);
        document
          .querySelector("html")
          ?.setAttribute(
            "data-theme",
            `${colorSchema[schemaPosition]}-${colorCategory[categoryPosition]}`
          );
      }

      if (event.key === "ArrowDown" && categoryPosition > 0) {
        categoryPosition -= 1;
        console.log(colorCategory[categoryPosition]);
        document
          .querySelector("html")
          ?.setAttribute(
            "data-theme",
            `${colorSchema[schemaPosition]}-${colorCategory[categoryPosition]}`
          );
      }
    });

    return null;
  }

  const pagePath = location
    ? location.pathname + location.search + location.hash
    : undefined;
  setTimeout(() => {
    if (typeof window.gtag === "function") {
      window.gtag("event", "page_view", { page_path: pagePath });
    }
  }, 100);
  return true;
};

export const wrapPageElement = ({ element, props }: any) => {
  return <Layout {...props}>{element}</Layout>;
};
